.auth-layout{
  min-height: 100vh;
background-color: #f6f8fc;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  display: none !important;
}